<div class="container-fluid">
  <div class="page-title">
    <div class="row">
      <div class="col-6">
        <h3>{{title}}</h3>
      </div>
      <div class="col-6">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a [routerLink]="'/dashboard/default'">
              <app-feather-icons [icon]="'home'" style="cursor: pointer;"></app-feather-icons>
            </a>
          </li>
          <ng-container *ngFor="let item of items;let i = index;">
            <li *ngIf="i < items.length-1" class="breadcrumb-item" style="cursor: pointer;" (click)="openBreadCrumb()">{{item}}</li>
          </ng-container>
          <li class="breadcrumb-item active">{{items[items.length-1]}}</li>
        </ol>
      </div>
    </div>
  </div>
</div>
