import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {

  public show: boolean = false;

  @Output() onSave = new EventEmitter<any>()
  userForm = new FormGroup({
    userName: new FormControl('', [Validators.required, Validators.pattern("^[^\\s].*$")]),
  })

  constructor(private servive: CommonService, private toaster: ToastrService) { }

  ngOnInit(): void {
  }
  tempStoreUserName = null;

  submitForm(userName) {
    if (!this.userForm.valid) {
      Object.keys(this.userForm.controls).forEach(key => {
        const control = this.userForm.get(key);
        if (control instanceof FormControl) {
          control.markAsTouched({ onlySelf: true });
        }
      })
    } else {
      this.userForm.controls['userName'].reset();
      console.log(userName);
      let formdata = new FormData();
      formdata.append('userName', userName);
      this.tempStoreUserName = userName;
      this.servive.sendOTP(formdata).subscribe(res => {
        console.log("resres", res);
        if (res.success == true) {
          // this.isSendMail = false;
          this.onSave.emit(userName);
        } else {
          this.toaster.error(res.responseMessage, 'Error');
        }
      })
    }
  }
  showPassword() {
    this.show = !this.show;
  }

}
