import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  @Output() onPwdChange = new EventEmitter<any>()
  changePwdForm = new FormGroup({
    oldPassword: new FormControl('', [Validators.required]),
    newPassword: new FormControl('', [Validators.required]),
  })
  constructor(private commonSerice: CommonService, private toaster: ToastrService) { }

  ngOnInit(): void {
  }

  submitForm(oldPwd, newPwd) {
    if (!this.changePwdForm.valid) {
      Object.keys(this.changePwdForm.controls).forEach(key => {
        const control = this.changePwdForm.get(key);
        if (control instanceof FormControl) {
          control.markAsTouched({ onlySelf: true });
        }
      })
    } else if (this.oldPwdCheck == true) {
      let formdata = new FormData();
      formdata.append('oldPassword', oldPwd);
      formdata.append('newPassword', newPwd);
      this.commonSerice.changePassword(formdata).subscribe(res => {
        console.log("res", res);
        if (res.success == true) {
          this.toaster.success("Password has been changed", 'Success');
          this.onPwdChange.emit();
        } else {
          this.toaster.error("Password not changed, please try again", 'Error');
        }
      })
    }
    console.log("oldPwd", oldPwd);
    console.log("newPwd", newPwd);
  }

  checkOldPassword(oldPwd) {
    let formdata = new FormData();
    formdata.append('oldPassword', oldPwd);
    this.commonSerice.checkOldPassword(formdata).subscribe(res => {
      console.log("res", res);
      if (res.success == true) {
        this.oldPwdCheck = true;
      } else
        this.oldPwdCheck = false;
    })
  }
  oldPwdCheck: boolean;
  setFlag() {
    // this.entity.mobileNo?'':this.checkMobileFlag=true
    this.oldPwdCheck = true;
  }
}
