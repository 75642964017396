import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent implements OnInit {

  @Output() onVerify = new EventEmitter<any>()
  @Input() userName
  otpForm = new FormGroup({
    otp1: new FormControl('', [Validators.required]),
    otp2: new FormControl('', [Validators.required]),
    otp3: new FormControl('', [Validators.required]),
    otp4: new FormControl('', [Validators.required]),
    otp5: new FormControl('', [Validators.required]),
    otp6: new FormControl('', [Validators.required]),

  })
  constructor(private servive: CommonService, private toaster: ToastrService) { }

  ngOnInit(): void {
  }
  verifyOTP(a, b, c, d, e, f) {
    console.log(a + b + c + d + e + f);
    let formdata = new FormData();
    formdata.append('userName', this.userName);
    formdata.append('otp', a + b + c + d + e + f);
    let pwdOTP = a + b + c + d + e + f;
    this.servive.verifyOTP(this.userName, pwdOTP).subscribe(res => {
      console.log("resres", res);
      if (res.success == true) {
        this.onVerify.emit();
      } else {
        this.toaster.error(res.responseMessage, 'Error');
      }
    })
  }

  onDigitInput(event) {
    const charCode = (event.which) ? event.which : event.keyCode;
    let element;
    if (event.code !== 'Backspace' && !(charCode > 31 && (charCode < 48 || charCode > 57) && (charCode < 96 || charCode > 105)))
      element = event.srcElement.nextElementSibling;

    if (event.code === 'Backspace' && !(charCode > 31 && (charCode < 48 || charCode > 57) && (charCode < 96 || charCode > 105)))
      element = event.srcElement.previousElementSibling;

    if (element == null)
      return;
    else
      element.focus();
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    return true;
  }

}
