<div class="container-fluid p-0">
  <div class="row">
    <div class="col-12">
      <div class="login-card">
        <div>
          <h2 class="text-center logo">Office Management</h2>
          <!-- <div>
            <a class="logo" routerLink='/'>
              <img class="img-fluid for-light" src="assets/images/logo/login.png" alt="looginpage">
              <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="looginpage">
            </a>
          </div> -->
          <div class="login-main">
            <form class="theme-form" [formGroup]="loginForm">
              <h4 class="text-center">Sign in to Account</h4>
              <p class="text-center">Enter your name & password to login</p>
              <div class="form-group">
                <label class="col-form-label">Username</label>
                <input class="form-control" type="text" required="" placeholder="Enter Username"
                  formControlName="userName" [ngModel]="entity.userName">
                <div *ngIf="loginForm.controls.userName.touched && loginForm.controls.userName.errors?.required"
                  class="text text-danger mt-1">
                  Username is required
                </div>
                <div *ngIf="loginForm.controls.userName.touched && loginForm.controls.userName.errors?.userName"
                  class="text text-danger mt-1">
                  Invalid Username
                </div>
              </div>
              <div class="form-group">
                <label class="col-form-label">Password</label>
                <input class="form-control" [type]="show ? 'text' : 'password'" formControlName="password"
                  [ngModel]="entity.password" required="" placeholder="*********">
                <div class="show-hide" (click)="showPassword()" *ngIf="!show"><span class="show"></span></div>
                <div class="show-hide" (click)="showPassword()" *ngIf="show"><span class="Hide"></span></div>
                <div *ngIf="loginForm.controls.password.touched && loginForm.controls.password.errors?.required"
                  class="text text-danger mt-1">
                  Password is required
                </div>
              </div>
              <div class="form-group mt-4">
                <div class="checkbox p-0">
                  <input id="checkbox1" type="checkbox">
                  <label class="text-muted" for="checkbox1">Remember password</label>
                </div>
                <a href="javascript:void(0)" (click)="forgetPassword(sendMail)" class="link">Forgot password?</a>
                <button class="btn btn-primary btn-block" [class.loader--text]="authService.showLoader"
                  (click)="login()" type="button"><span>{{ authService.showLoader ? '' : 'Login' }}</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- send otp to email -->
<ng-template #sendMail let-modal>
  <div class="modal-body">
    <div class="container-fluid p-0">
      <div class="row">
        <div class="col-12">
          <div class="modal-bg addtocart">
            <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-hidden="true">×</button>
            <br>
            <app-forget-password (onSave)="onSaveFun($event,verifyMail)"></app-forget-password>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- verify Otp -->
<ng-template #verifyMail let-modal>
  <div class="modal-body">
    <div class="container-fluid p-0">
      <div class="row">
        <div class="col-12">
          <div class="modal-bg addtocart">
            <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-hidden="true">×</button>
            <br>
            <app-verify-email [userName]="tempStoreEmail" (onVerify)="onVerifyFun(newPassword)"></app-verify-email>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<!-- new Password -->
<ng-template #newPassword let-modal>
  <div class="modal-body">
    <div class="container-fluid p-0">
      <div class="row">
        <div class="col-12">
          <div class="modal-bg addtocart">
            <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-hidden="true">×</button>
            <br>
            <app-new-password [userName]="tempStoreEmail" (onNewPwd)="onNewPwdFun()"></app-new-password>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>