<div class="container">
  <div class="row">
    <div class="col-lg-12">
      <h6>User Name</h6>
      <form [formGroup]="userForm">
        <div class="row">
          <div class="col-md-12">
            <input type="text" formControlName="userName" #userName class="form-control"
              placeholder="Enter Your User Name" required="">
            <div
              *ngIf="userForm.controls.userName.invalid && (userForm.controls.userName.dirty || userForm.controls.userName.touched)"
              class="text-danger">
              <div *ngIf="userForm.controls.userName.errors.required">{{'This field is required'|translate}}
              </div>
              <div *ngIf="userForm.controls.userName.errors.pattern">{{'Invalid User Name'|translate}}
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <button class="btn btn-primary mr-4 mt-4 mb-4" (click)="submitForm(userName.value)">Send OTP</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>