import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { ColumnMode, SortType } from '@swimlane/ngx-datatable';
import { Pagination } from 'src/app/core/modal/pagination-data';
import { GridSetting } from './defualtGridSettings';
import { NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
import { Filters } from 'src/app/core/modal/pagination-filters';
import { CommonService } from '../../services/common.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import * as XLSX from 'xlsx';
import { ToastrService } from 'ngx-toastr';
import autoTable from 'jspdf-autotable';
import { jsPDF } from 'jspdf';



@Component({
  selector: 'app-table-grid',
  templateUrl: './table-grid.component.html',
  styleUrls: ['./table-grid.component.scss'],
})
export class TableGridComponent implements OnInit {

  @Input() finalTotalAmount: number;
  @Input() finalPaidAmount: number;
  @Input() finalRemainingAmount: number;
  @ViewChild('myButton', { static: true }) myButton: ElementRef;

  onEnter() {
    this.myButton.nativeElement.click();
  }
  @Input() title: string;
  @Input() title2: string;
  @Input() settings: any
  @Input() dataSource: any
  @Input() exportDataList: any
  @Input() isInvoicePage: boolean = false;
  @Input() isCompanyFilter: boolean = false;
  @Input() isExpenseFilter: boolean = false;
  @Output() onFilterChange = new EventEmitter<any>();
  @Output() addOnClick = new EventEmitter<any>();
  @ViewChild('ngSelectComponent') searchreff: NgSelectComponent;
  pagination = new Pagination();
  ObjetKyes = Object.keys;
  paginationMenuList: any[] = [];
  paginationMenuData = localStorage.getItem('paginationMenuList');
  rows = [];
  dataSourceList: any[] = [];
  dataKey: any[] = [];
  page = 1;
  gridSettings: any = GridSetting;

  columns = [{ name: 'firstName', test: 'sd' }, { name: 'lastName', test: 'sd' }, { name: 'middelName', test: 'sd' }];

  ColumnMode = ColumnMode;
  SortType = SortType;

  constructor(config: NgbPaginationConfig, private comon: CommonService, public toster: ToastrService) {
    config.size = 'sm';
    config.boundaryLinks = true;
  }
  ngOnInit(): void {
    console.log("exportData:::", this.exportDataList);

  }
  ngOnChanges() {
    if (!this.dataSource && this.dataSource?.length === 0) {
      return;
    }
    this.setGridSettings();
  }
  setGridSettings() {
    this.settings.columns.forEach(key => {
      this.dataSourceList = this.dataSource?.map(data => {
        if (!data.hasOwnProperty(key.colKey)) {
          data[key.colKey] = null;
          return data;
        }
      })
    })
    this.dataKey = this.settings.columns.map((col) => {
      return { column: col.colKey, columnValue: col.customControl === undefined ? null : col.customControl };
    })
    this.setOptionValue();
  }
  setOptionValue() {
    Object.entries(this.settings).forEach(obj => {
      if (this.gridSettings.hasOwnProperty(obj[0])) {
        this.gridSettings[obj[0]] = obj[1];
      }
    })
  }
  onFilter(event) {

    // dinesh code
    let filters = new Filters();
    filters.filterColumn = 'any';
    filters.filterValue = event.target.value;
    let serverObj = new Array<Filters>(filters);
    this.pagination.filters = filters && filters.filterValue ? JSON.parse(JSON.stringify(serverObj)) : null;
    this.pagination.page = 1;
    this.onFilterChange.emit(this.pagination);
  }
  onFilter2(value) {
    // dinesh code
    if (!this.pagination.filters || this.pagination.filters.length == 0) {
      this.pagination.filters = new Array<Filters>();
    }
    let filters = new Filters();
    filters.filterColumn = 'any';
    filters.filterValue = value;
    let serverObj = new Array<Filters>(filters);
    this.pagination.filters.filter(obj => {
      if (obj.filterColumn == 'any') {
        obj.filterValue = filters.filterValue;
        this.onFilterChange.emit(this.pagination);
        this.colmFlag = true;
      }
    })
    if (this.colmFlag != true) {
      this.pagination.filters.push(filters);
      this.pagination.page = 1;
      this.onFilterChange.emit(this.pagination);
    }
    // this.pagination.filters = filters && filters.filterValue ? JSON.parse(JSON.stringify(serverObj)) : null;

  }
  onSort(key) {
    this.pagination.sortOrder = this.pagination && this.pagination.sortOrder === 0 ? 1 : 0;
    this.pagination.sortBy = key;
    this.onFilterChange.emit(this.pagination);
  }
  openForm() {
    this.addOnClick.emit();
  }
  onPageChange(page) {
    this.pagination.page = page
    this.onFilterChange.emit(this.pagination);
  }
  onPageSizeChange(event) {
    if (event) {
      let pageSize = event.target.value;
      this.pagination.pageSize = pageSize;
      this.onFilterChange.emit(this.pagination);
    }
  }

  CompanyList = [];
  getCompanyList() {
    if (this.CompanyList.length === 0) {
      this.comon.getCompanydata().subscribe(res => {
        this.CompanyList = res.data;

      })
    }
  }
  colmFlag = false;
  onCompanyChange(event) {
    console.log(event);
    if (!this.pagination.filters || this.pagination.filters.length == 0) {
      this.pagination.filters = new Array<Filters>();
    }
    let filters = new Filters();
    filters.filterColumn = 'company';
    filters.idList = [];
    event.filter(e => {
      filters.idList.push(e.id);
    })
    this.pagination.filters.filter(obj => {
      if (obj.filterColumn == 'company') {
        obj.idList = filters.idList;
        // this.onFilterChange.emit(this.pagination);
        this.colmFlag = true;
      }
    })
    if (this.colmFlag != true) {
      this.pagination.filters.push(filters);
    }
    this.onFilterChange.emit(this.pagination);

  }

  //filter
  yearList = [];
  getYearList() {
    if ((!this.yearList || this.yearList.length === 0)) {
      let currentYear: number = new Date().getFullYear();
      for (let i = (currentYear - 2); i < (currentYear + 3); i++) {
        this.yearList.push(i);
      }
      console.log(this.yearList);

    }
  }

  monthList = [{ name: 'Jan', month: 1 }
    , { name: 'Feb', month: 2 }
    , { name: 'Mar', month: 3 }
    , { name: 'Apr', month: 4 }
    , { name: 'May', month: 5 }
    , { name: 'Jun', month: 6 }
    , { name: 'Jul', month: 7 }
    , { name: 'Aug', month: 8 }
    , { name: 'Sep', month: 9 }
    , { name: 'Oct', month: 10 }
    , { name: 'Nov', month: 11 }
    , { name: 'Dec', month: 12 }
  ]

  isYear: boolean = false;
  monthModal = null;
  onYearChange(event) {
    // console.log("this.searchInput.nativeElement.value ", this.searchreff.clearModel());
    if (event) {
      let filter = new Filters();
      filter.filterColumn = "year";
      filter.filterValue = event;
      if (this.pagination.filters == null) {
        this.pagination.filters = []
      }
      let x = this.pagination.filters.find(x => x.filterColumn == filter.filterColumn);
      if (x != null) {
        this.pagination.filters.filter(obj => {
          if (obj.filterColumn == filter.filterColumn) {
            obj.filterValue = filter.filterValue;
          }
        })
      } else {
        this.pagination.filters.push(filter);
      }
      this.pagination.page = 1;
      // this.onFilterChange.emit(this.pagination);
    } else {
      this.monthModal = null;
      if (!this.pagination.filters || this.pagination.filters.length == 0) {
        this.pagination.filters = new Array<Filters>();
      }
      this.pagination.filters = this.pagination.filters.filter(obj => {
        return obj.filterColumn != 'month' && obj.filterColumn != 'year';
      })
      // this.onFilterChange.emit(this.pagination);
    }
    this.onFilterChange.emit(this.pagination);
  }

  onMonthChang(event) {
    if (event) {
      let filter = new Filters();
      filter.filterColumn = "month";
      filter.filterValue = event.month;
      if (this.pagination.filters == null) {
        this.pagination.filters = []
      }
      let x = this.pagination.filters.find(x => x.filterColumn == filter.filterColumn);
      if (x != null) {
        this.pagination.filters.filter(obj => {
          if (obj.filterColumn == filter.filterColumn) {
            obj.filterValue = filter.filterValue;
          }
        })
      } else {
        this.pagination.filters.push(filter);
      }
      this.pagination.page = 1;
      // this.onFilterChange.emit(this.pagination);
    } else {
      if (!this.pagination.filters || this.pagination.filters.length == 0) {
        this.pagination.filters = new Array<Filters>();
      }
      this.pagination.filters = this.pagination.filters.filter(obj => {
        return obj.filterColumn != 'month';
      })
      // this.onFilterChange.emit(this.pagination);
    }
    this.onFilterChange.emit(this.pagination);
  }





  //export to excel data
  exportToExcelData() {
    if (this.exportDataList != null && this.exportDataList.length > 0) {
      this.exportDataList.filter(res => {
        if (res.excelData != null && res.excelData.length > 0) {
          res.excelData.filter(obj => {
            if (obj.data != null && obj.data.length > 0) {
              let data = obj.data;
              let columnWidths = obj.columnWidths;
              let fileName = obj.fileName;
              this.exportToExcel(data, fileName, columnWidths);
              this.toster.success("Excel download successfully", "Success")
            } else {
              this.toster.error("Data not found", "Error")
            }
          })
        }
      })
    }
  }

  exportToExcel(data: any[], filename: string, columnWidth: any[]) {
    let file = filename + '.xlsx'
    const header = Object.keys(data[0]);
    const worksheet = XLSX.utils.json_to_sheet(data, { header });
    // Set column width to auto size
    // const columnWidths = header.map(column => {
    //   return { width: column.length + 2 };
    // });
    // worksheet['!cols'] = columnWidths;
    const columnWidths = columnWidth;
    worksheet['!cols'] = columnWidths;
    const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, file);
  }

  private saveAsExcelFile(buffer: any, filename: string) {
    const data: Blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(data);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }


  exportToPdfData() {
    if (this.exportDataList != null && this.exportDataList.length > 0) {
      this.exportDataList.filter(res => {
        if (res.pdfData != null && res.pdfData.length > 0) {
          res.pdfData.filter(obj => {
            if (obj.tableRows != null && obj.tableRows.length > 0) {
              let tableRows = obj.tableRows;
              let tableHeader = obj.tableHeader;
              let fileName = obj.fileName;
              let header = obj.header;
              this.exportToPdf(tableHeader, tableRows, header, fileName);
              this.toster.success("Pdf download successfully", "Success")
            } else {
              this.toster.error("Data not found", "Error")
            }
          })
        }
      })
    }
  }



  exportToPdf(tableHeader: any[], tableRows: any[], title: string, fileName: string) {
    const doc = new jsPDF('landscape');
    const header = title;
    const textWidth = doc.getTextDimensions(header).w;
    const pageWidth = doc.internal.pageSize.width;
    const xPos = (pageWidth - textWidth) / 2;
    const headerHeight = doc.getTextDimensions(header).h + 10; // Calculate height of header
    // doc.text(header, 14, 10); 
    doc.text(header, xPos, 11);
    doc.setLineWidth(0.5); // Set line width for header
    doc.line(14, headerHeight, doc.internal.pageSize.width - 14, headerHeight); // Add header line
    autoTable(doc, {
      head: [tableHeader],
      body: tableRows,
      theme: 'grid',
      startY: headerHeight + 5, // Set table start position below header
      headStyles: { fillColor: [115, 102, 255] }
    }
    );
    doc.save(fileName + '.pdf');
  }

  yearList2 = [];
  getFinacialYearList() {
    this.yearList2 = [];
    if ((!this.yearList2 || this.yearList2.length === 0)) {
      const startYear = new Date().getFullYear() - 5; // set the start year
      const currentYear = new Date().getFullYear() + 5;
      for (let year = startYear; year <= currentYear; year++) {
        const financialYear = year.toString() + '-' + (year + 1).toString().substr(-2);
        let obj = {
          "name": financialYear,
          "value": year
        }
        this.yearList2.push(obj);
      }
    }
  }


}
