<div class="container">
    <div class="row">
        <div class="col-lg-12">
            <h3>Reset password</h3>
            <form [formGroup]="resetForm">
                <div class="row">
                    <div class="col-md-12">
                        <label for="">New Password</label>
                        <input type="text" formControlName="newPwd" #newPwd class="form-control"
                            placeholder="Enter New Password" required="">
                        <div *ngIf="resetForm.controls.newPwd.touched && resetForm.controls.newPwd.errors?.required"
                            class="text text-danger">
                            New Password is required.
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <label for="">Confirm Password</label>
                        <input type="text" formControlName="confirmNewPwd" #confirmNewPwd class="form-control"
                            placeholder="Enter New Password" required="">
                        <div *ngIf="resetForm.controls.confirmNewPwd.touched && resetForm.controls.confirmNewPwd.errors?.required"
                            class="text text-danger">
                            Confirm Password is required.
                        </div>
                    </div>
                </div>
                <span *ngIf="isSamePwd == false" class="text-danger">New password and confirm password must be
                    same.</span>
                <div class="row">
                    <div class="col-md-12">
                        <button class="btn btn-primary mr-4 mt-4 mb-4"
                            (click)="submitForm(newPwd.value,confirmNewPwd.value)">Reset</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>