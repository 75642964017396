import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { CommonService } from '../../services/common.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(private commonService: CommonService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let currentUser = localStorage.getItem('authUser');
    const token: string = localStorage.getItem('authToken');
    if (currentUser && token) {
      if (!request.headers.has('Authorization')) {
        request = request.clone({
          setHeaders: {
            Authorization: JSON.parse(localStorage.getItem('authToken'))
          }
        });
      }
      // request = request.clone({ headers: request.headers.set('Authorization', JSON.parse(localStorage.getItem('authToken'))) });
    }
    return next.handle(request);
  }
}
