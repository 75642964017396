import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { AuthService } from '../../shared/services/firebase/auth.service';
import { loginModel } from '../model/loginModel'
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  @Input() entity = new loginModel();
  public show: boolean = false;
  public loginForm: FormGroup;
  public errorMessage: any;

  constructor(public authService: AuthService, private fb: FormBuilder, private router: Router, private toastr: ToastrService, private modalService: NgbModal,) {

  }

  ngOnInit() {
    this.loginForm = this.fb.group({
      userName: ['', [Validators.required]],
      password: ['', [Validators.required]]
    });
  }

  showPassword() {
    this.show = !this.show;
  }

  // Simple Login
  login() {
    if (!this.loginForm.valid) {
      Object.keys(this.loginForm.controls).forEach(key => {
        const control = this.loginForm.get(key);
        if (control instanceof FormControl) {
          control.markAsTouched({ onlySelf: true });
        }
      })
    } else {
      this.loginUser();
    }
  }
  loginUser() {
    this.authService.SignIn(this.loginForm.value['userName'], this.loginForm.value['password']).pipe(first())
      .subscribe(
        data => {
          if (data.success == false) {
            this.toastr?.error("Invalid username or password", "Error");
            // this.loginForm.reset();
          }
          else {
            this.router.navigate(['/dashboard/default']);
          }
        },
        error => {
        });
  }

  //forgot-password

  forgetPassword(template) {
    this.modalService.open(template, { size: 'md'});
  }
  //template
  tempStoreEmail = null;
  isSendMail: boolean = true;
  onSaveFun(e, tmpReff) {
    console.log("successfully call", e);
    this.tempStoreEmail = e;
    this.modalService.dismissAll();
    this.modalService.open(tmpReff, { size: 'md' });
  }
  //verify Mail

  onVerifyFun(newPasswordTmp) {
    console.log("onVerifyFun");
    this.modalService.dismissAll();
    this.modalService.open(newPasswordTmp, { size: 'md' });
  }
  //new pwd
  onNewPwdFun() {
    console.log("newpwdfuncall");
    this.modalService.dismissAll();
  }

}
