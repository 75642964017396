import { Injectable, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { auth } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import * as firebase from 'firebase/app';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
export interface User {
  uid: string;
  userName: string;
  displayName: string;
  photoURL: string;
  userNames: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnInit {
  AuthToken() {
    return localStorage.getItem("authToken");
  }
  AuthUser() {
    return localStorage.getItem("authUser");
  }

  public userData: any;
  public user: firebase.User;
  public showLoader: boolean = false;
  currentUserSubject: BehaviorSubject<User>;
  currentUser:Observable<User>

  constructor(public afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    public router: Router,
    public ngZone: NgZone,
    public toster: ToastrService,
    private cookieService: CookieService,
    private http:HttpClient) {
      this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('authUser')));
      this.currentUser = this.currentUserSubject.asObservable();
      this.afAuth.authState.subscribe(user => {
        if (user) {
          this.userData = user;
          cookieService.set('user', JSON.stringify(this.userData));
          localStorage.setItem('user', JSON.stringify(this.userData));
          JSON.parse(localStorage.getItem('user'));
        } else {
          //localStorage.setItem('user', null);
          localStorage.setItem('user', JSON.stringify({ "name": "kiran" }));
          JSON.parse(localStorage.getItem('user'));
        }
      });
    }

  ngOnInit(): void { }

  // sign in function
  SignIn(userName, password) {
    let obj: any = {};
    obj.userName = userName;
    obj.password = password;
    return this.http.post<any>(environment.SERVER_URL + 'login', obj).pipe(map(res => {
      if (res && res.data?.token) {
        this.showLoader = false;
        let token = res.data?.tokenPrefix + res.data?.token;
        localStorage.setItem('authToken', JSON.stringify(token));
        localStorage.setItem('authUser', JSON.stringify(res.data));
        this.currentUserSubject.next(res.data);
      }
      return res;
    }))
  }

  // main verification function
  SendVerificationMail() {
    return this.afAuth.auth.currentUser.sendEmailVerification()
      .then(() => {
        this.router.navigate(['/dashboard/default']);
      })
  }

  // Sign in with Facebook
  signInFacebok() {
    return this.AuthLogin(new auth.FacebookAuthProvider());
  }

  // Sign in with Twitter
  signInTwitter() {
    return this.AuthLogin(new auth.TwitterAuthProvider());
  }

  // Sign in with Google
  GoogleAuth() {
    return this.AuthLogin(new auth.GoogleAuthProvider());
  }

  ForgotPassword(passwordResetEmail) {
    return this.afAuth.auth.sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        window.alert('Password reset email sent, check your inbox.');
      }).catch((error) => {
        window.alert(error);
      });
  }

  // Authentication for Login
  AuthLogin(provider) {
    return this.afAuth.auth.signInWithPopup(provider)
      .then((result) => {
        this.ngZone.run(() => {
          this.router.navigate(['/dashboard/default']);
        });
        this.SetUserData(result.user);
      }).catch((error) => {
        window.alert(error);
      });
  }

  // Set user
  SetUserData(user) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    const userData: User = {
      userName: user.userName,
      displayName: user.displayName,
      uid: user.uid,
      photoURL: user.photoURL || 'assets/dashboeard/boy-2.png',
      userNames: user.userNames
    };
    userRef.delete().then(function () {})
          .catch(function (error) {});
    return userRef.set(userData, {
      merge: true
    });
  }
  logoutUser() {
    localStorage.clear();
    this.router.navigate(['/auth/login']);
  }
  // Sign out
  SignOut() {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    return this.afAuth.auth.signOut().then(() => {
      this.showLoader = false;
      localStorage.clear();
      this.cookieService.deleteAll('user', '/auth/login');
      this.router.navigate(['/auth/login']);
    });
  }

  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return (user != null && user.userNames != false) ? true : false;
  }

}