<div class="container">
    <div class="row">
        <form action="" [formGroup]="otpForm">
            <div class="row">
                <div class="col-lg-12">
                    <div class="form-group">
                        <div class="container height-100 d-flex justify-content-center align-items-center">
                            <div class="position-relative">
                                <div class="card p-2 text-center">
                                    <h6>Please enter the one time password <br> to verify your account</h6>
                                    <div> <span>A code has been sent to</span> <small>&nbsp; {{userName}}</small> </div>
                                    <div id="otp" class="inputs d-flex flex-row justify-content-center mt-2">
                                        <input #otp1 formControlName="otp1" class="m-2 text-center form-control rounded"
                                            type="text" id="first" maxlength="1" (keyup)="onDigitInput($event)"
                                            (keypress)="numberOnly($event)" />
                                        <input #otp2 formControlName="otp2" class="m-2 text-center form-control rounded"
                                            type="text" id="second" maxlength="1" (keyup)="onDigitInput($event)"
                                            (keypress)="numberOnly($event)" />
                                        <input #otp3 formControlName="otp3" class="m-2 text-center form-control rounded"
                                            type="text" id="third" maxlength="1" (keyup)="onDigitInput($event)"
                                            (keypress)="numberOnly($event)" />
                                        <input #otp4 formControlName="otp4" class="m-2 text-center form-control rounded"
                                            type="text" id="fourth" maxlength="1" (keyup)="onDigitInput($event)"
                                            (keypress)="numberOnly($event)" />
                                        <input #otp5 formControlName="otp5" class="m-2 text-center form-control rounded"
                                            type="text" id="fifth" maxlength="1" (keyup)="onDigitInput($event)"
                                            (keypress)="numberOnly($event)" />
                                        <input #otp6 formControlName="otp6" class="m-2 text-center form-control rounded"
                                            type="text" id="sixth" maxlength="1" (keyup)="onDigitInput($event)"
                                            (keypress)="numberOnly($event)" />
                                    </div>
                                    <div class="mt-4 mb-4"> <button
                                            (click)="verifyOTP(otp1.value,otp2.value,otp3.value,otp4.value,otp5.value,otp6.value)"
                                            class="btn btn-danger px-4 validate">Validate</button> </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <div class="form-group">
                        <!-- <button class="btn btn-success ml-3" (click)="verifyOTP(otp.value)">Verify OTP</button> -->
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>