import { Directive, HostListener } from '@angular/core'
@Directive({
    selector: '[spaceNotAllow]'
})
export class SpaceNotAllowed {
    @HostListener("keypress", ['$event'])
    public spaceNotAllow(event) {
        if (event.target.selectionStart === 0 && event.code === "Space") {
            event.preventDefault();
        }
    }
}