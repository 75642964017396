export const GridSetting = {
    showFooter: false,
    showPagination: false,
    perPage: 0,
    pageSizeOptions: [10, 15, 20, 50, 100],
    sort: true,
    serverSideSortPagination: false,
    customControl: null,
    moduleId: null,
    pageTotalLength: 0,
    columns: [],
    serverSideFilter: true,
    totalElements: 0
}