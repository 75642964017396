<div class="container">
    <div class="row">
        <div class="col-lg-12">
            <h6>Change Password</h6>
            <form [formGroup]="changePwdForm">
                <div class="row">
                    <div class="col-md-12">
                        <input type="password" formControlName="oldPassword" #oldPassword class="form-control"
                            placeholder="Enter Old Password" required=""
                            (focusout)="checkOldPassword(oldPassword.value)" (keyup)="setFlag()">
                        <div *ngIf="changePwdForm.controls.oldPassword.touched && changePwdForm.controls.oldPassword.errors?.required"
                            class="text text-danger">
                            Old Password is required.
                        </div>
                        <div *ngIf="oldPwdCheck == false" class="text-danger mb-3">Invalid old password.</div>
                    </div>
                    <div class="col-md-12 mt-2">
                        <input type="password" formControlName="newPassword" #newPassword class="form-control"
                            placeholder="Enter New Password" required="">
                        <div *ngIf="changePwdForm.controls.newPassword.touched && changePwdForm.controls.newPassword.errors?.required"
                            class="text text-danger">
                            New Password is required.
                        </div>
                    </div>
                </div>

                <div class="row ">
                    <div class="col-md-12">
                        <button class="btn btn-primary mr-4 mt-4"
                            (click)="submitForm(oldPassword.value,newPassword.value)">Change</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>