<div class="media profile-media">
  <img class="b-r-10" src="assets/images/dashboard/profile.jpg" alt="">
  <div class="media-body"><span>Office Managment User</span>
    <p class="mb-0 font-roboto">Admin <i class="middle fa fa-angle-down"></i></p>
  </div>
</div>
<ul class="profile-dropdown onhover-show-div">
  <li><a href="#">
      <app-feather-icons [icon]="'user'"></app-feather-icons><span>Account </span>
    </a></li>
  <!-- <li><a href="#"><app-feather-icons [icon]="'mail'"></app-feather-icons><span>Inbox</span></a></li>
  <li><a href="#"><app-feather-icons [icon]="'file-text'"></app-feather-icons><span>Taskboard</span></a></li>
  <li><a href="#"><app-feather-icons [icon]="'settings'"></app-feather-icons><span>Settings</span></a></li> -->

  <li><a href="javascript:void(0)" (click)="changePwd(changePwdTmp)">
      <app-feather-icons [icon]="'edit'"></app-feather-icons><span>Change Password</span>
    </a></li>
  <li><a href="javascript:void(0)" (click)="logoutuser()">
      <app-feather-icons [icon]="'log-out'"></app-feather-icons><span>Logout</span>
    </a></li>
</ul>

<ng-template #changePwdTmp let-modal>
  <div class="modal-body">
    <div class="container-fluid p-0">
      <div class="row">
        <div class="col-12">
          <div class="modal-bg addtocart">
            <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-hidden="true">×</button>
            <br>
            <app-change-password (onPwdChange)="onPwdChangeFun()"></app-change-password>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>