import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseData } from 'src/app/core/modal/base-save-update-response-modal';
import { environment } from 'src/environments/environment';
import { State } from '../../core/modal/state';
import { District } from '../../core/modal/district';
import { NatureOfOwnership } from '../../pages/client/model/natureOfOwnership';
import { da } from 'date-fns/locale';
import { Role } from 'src/app/core/modal/role';
import { ExpenseHead } from '../../pages/expense-head/model/expense-head';
@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private http: HttpClient) { }

  downloadEmployeeImage(id): Observable<Blob> {
    return this.http.get(environment.SERVER_URL + "fileDownload/getEmployeeDocument/" + id, { responseType: 'blob' });
  }
  sendQuoation(id) {
    return this.http.get<ResponseData<State>>(environment.SERVER_URL + "quotation/sendQuotation/" + id);
  }

  getQuotationById(id) {
    return this.http.get<ResponseData<State>>(environment.SERVER_URL + "quotation/" + id);

  }

  getUnPaidExpenseOfClient(id) {
    return this.http.get<ResponseData<State>>(environment.SERVER_URL + "expense/getUnpaidExpenseByClientId/" + id);

  }
  getInvoiceList() {
    return this.http.get<ResponseData<State>>(environment.SERVER_URL + "invoice");

  }

  public sendOTP(userName): Observable<any> {
    return this.http.post<any>(
      environment.SERVER_URL + 'sendmail', userName
    );
  }
  public verifyOTP(userName, pwdOTP): Observable<any> {
    return this.http.get<any>(
      environment.SERVER_URL + 'verifyOtp?userName=' + userName + '&otp=' + pwdOTP
    );
  }
  public resetPassword(data): Observable<any> {
    return this.http.post<any>(
      environment.SERVER_URL + 'forgotPassword', data
    );
  }

  public changePassword(data): Observable<any> {
    return this.http.post<any>(
      environment.SERVER_URL + 'changePassword', data
    );
  }

  public checkOldPassword(data): Observable<any> {
    return this.http.post<any>(
      environment.SERVER_URL + 'checkOldPassword', data
    );
  }


  public getStateList(): Observable<ResponseData<State>> {
    return this.http.get<ResponseData<State>>(
      environment.SERVER_URL + 'state'
    );
  }
  public getQuotationList(): Observable<ResponseData<State>> {
    return this.http.get<ResponseData<State>>(
      environment.SERVER_URL + 'quotation'
    );
  }


  public getPaymentFrequencyList(): Observable<ResponseData<any>> {
    return this.http.get<ResponseData<any>>(
      environment.SERVER_URL + 'paymentFrequency'
    );
  }
  public getParentExpenseHeadList(): Observable<ResponseData<ExpenseHead>> {
    return this.http.get<ResponseData<ExpenseHead>>(
      environment.SERVER_URL + 'expenseHead/parentExpenseHead'
    );
  }

  public getRoleList(): Observable<ResponseData<Role>> {
    return this.http.get<ResponseData<Role>>(
      environment.SERVER_URL + 'role'
    );
  }

  public getNatureOfOwnershipList(): Observable<ResponseData<NatureOfOwnership>> {
    return this.http.get<ResponseData<NatureOfOwnership>>(
      environment.SERVER_URL + 'natureOfOwnership'
    );
  }

  public getDistrictByState(id: number): Observable<ResponseData<District>> {
    return this.http.get<ResponseData<District>>(
      environment.SERVER_URL + 'district/getDistrictByStateId/' + id
    );
  }

  public getActList(): Observable<ResponseData<any>> {
    return this.http.get<ResponseData<any>>(
      environment.SERVER_URL + 'act'
    );
  }
  public getChildAct(): Observable<ResponseData<any>> {
    return this.http.get<ResponseData<any>>(
      environment.SERVER_URL + 'act/child'
    );
  }

  public getexpenseHeaddataChild(): Observable<ResponseData<any>> {
    return this.http.get<ResponseData<any>>(environment.SERVER_URL + "expenseHead/childExpenseHead");
  }

  getexpenseHeaddata(): Observable<any> {
    return this.http.get(environment.SERVER_URL + "expenseHead");
  }
  getCompanydata(): Observable<any> {
    return this.http.get(environment.SERVER_URL + "company");
  }
  getExpanseStatusdata(): Observable<any> {
    return this.http.get(environment.SERVER_URL + "expenseStatus");
  }
  getPaymentModedata(): Observable<any> {
    return this.http.get(environment.SERVER_URL + "paymentMode");
  }
  saveExpensedata(data): Observable<any> {
    return this.http.post(environment.SERVER_URL + "expense/save", data)
  }
  editExpense(data, content) {
    return this.http.put(environment.SERVER_URL + "expense/update", data, content)
  }
  getClientdata(): Observable<any> {
    return this.http.get(environment.SERVER_URL + "client");
  }
  getActdata(): Observable<any> {
    return this.http.get(environment.SERVER_URL + "act");
  }
  getStageOfWorkdata(): Observable<any> {
    return this.http.get(environment.SERVER_URL + "stageOfWork");
  }
  geparentActdata(): Observable<any> {
    return this.http.get(environment.SERVER_URL + "parent");
  }
  getEmployeedata(): Observable<any> {
    return this.http.get(environment.SERVER_URL + "employee");
  }
  getComplianceFrequencydata(): Observable<any> {
    return this.http.get(environment.SERVER_URL + "complianceFrequency");
  }
  geMainservicedata(): Observable<any> {
    return this.http.get(environment.SERVER_URL + "act/parent");
  }

  getOtherInformationdata(): Observable<any> {
    return this.http.get(environment.SERVER_URL + "otherInformation/parent");
  }

  public getAllActByParentId(id): Observable<ResponseData<any>> {
    return this.http.get<ResponseData<State>>(
      environment.SERVER_URL + 'act/getAllActByParentId/' + id
    );
  }
  public getAllOtherInformationByParentId(id): Observable<ResponseData<any>> {
    return this.http.get<ResponseData<State>>(
      environment.SERVER_URL + 'otherInformation/getAllOtherInformationByParentId/' + id
    );
  }

  geMainotherInformationdata(): Observable<any> {
    return this.http.get(environment.SERVER_URL + "otherInformation/parent");
  }

  getOtherInfoList() {
    return this.http.get<ResponseData<any>>(environment.SERVER_URL + "otherInformation/getDefaultOtherInformation");

  }
  getcompanyList(): Observable<any> {
    return this.http.get(environment.SERVER_URL + "company");
  }

  public getAllInvoiceByClientId(id): Observable<ResponseData<any>> {
    return this.http.get<ResponseData<State>>(
      environment.SERVER_URL + 'invoice/getAllInvoiceByClientId/' + id
    );
  }
}

