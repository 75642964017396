import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  @Input() title: string;
  @Input() items: any[];
  @Input() active_item: string;
  @Output('breadcrumbClick')
  public breadcrumbClick: EventEmitter<any> = new EventEmitter<any>();
  
  constructor() {
  }

  ngOnInit() { }
  openBreadCrumb() {
    this.breadcrumbClick.emit();
  }
}
