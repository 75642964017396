//edit by kiran (original this)
// export class Pagination {
//     rowsPerPage: number = 100;
//     pageNumber: number = 1;
// }


import { Filters } from "./pagination-filters";
export class Pagination {
    page: number = 1;
    pageSize: number = 10;
    sortBy:string='id';
    sortOrder:number=0;
    filters:Array<Filters>;
}