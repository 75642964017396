import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../../../services/firebase/auth.service';
@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {

  constructor(public authService: AuthService, private modalService: NgbModal) { }

  ngOnInit() {
  }

  logoutuser() {
    this.authService.logoutUser();
  }
  //change password
  changePwd(tmp) {
    this.modalService.open(tmp, { size: 'lm' })
  }
  onPwdChangeFun() {
    this.modalService.dismissAll();
  }
} 
