import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss']
})
export class NewPasswordComponent implements OnInit {

  @Output() onNewPwd = new EventEmitter<any>()
  @Input() userName
  resetForm = new FormGroup({
    newPwd: new FormControl('', [Validators.required]),
    confirmNewPwd: new FormControl('', [Validators.required]),
  })

  constructor(private commonService: CommonService, private toaster: ToastrService) { }

  ngOnInit(): void {
  }
  isSamePwd: boolean = true;
  submitForm(newPwd, confirmPwd) {
    console.log("newPwd", newPwd);
    console.log("confirmPwd", confirmPwd);
    if (newPwd != confirmPwd) {
      this.isSamePwd = false;
      return;
    }
    let formdata = new FormData();
    formdata.append('userName', this.userName);
    formdata.append('newPassword', newPwd);
    this.commonService.resetPassword(formdata).subscribe(res => {
      console.log("resresres", res);
      if (res.success == true) {
        this.toaster.success('Your password has been changed', 'Success');
        this.onNewPwd.emit();
      } else {
        this.toaster.error('Your password has not been changed please try again', 'Error');
      }
    })

  }

}
