<link href="https://gitcdn.github.io/bootstrap-toggle/2.2.2/css/bootstrap-toggle.min.css" rel="stylesheet">
<script src="https://gitcdn.github.io/bootstrap-toggle/2.2.2/js/bootstrap-toggle.min.js"></script>
<div class="container-fluid">
    <div class="row">
        <div class="col-md-6" style="margin-top:35px">
            <h4>{{title | translate }}</h4>
        </div>
        <div class="col-md-6" style="margin-top:35px">
            <div style="text-align: right;float: right;">
                <button type="button" class="btn btn-outline-primary" style="font-weight:bold;"
                    (click)="openForm()">{{'Add New' | translate}}</button>
            </div>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-md-12 float-end">
            <ol class="breadcrumb" style="background-color: #f8f8f8;float: right;">
                <li class="breadcrumb-item">
                    <a class="mb-1" [routerLink]="'/dashboard/default'">
                        <app-feather-icons [icon]="'home'"></app-feather-icons>
                    </a>
                </li>
                <li class="breadcrumb-item">{{title2}}</li>
            </ol>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="row ml-2 mt-5 mr-2 p-0 ">
                    <div class="col-sm-5 col-md-5">
                        <div class="float-left">
                            <select (change)="onPageSizeChange($event)" class="form-control" style="font-size: 13px;">
                                <option value="10">10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                                <option value="70">70</option>
                                <option value="100">100</option>
                            </select>
                        </div>
                    </div>


                    <div class="col-sm-5 col-md-5">
                        <div class="float-right">
                            <input #search type="text" (keydown.enter)="onEnter()" spaceNotAllow
                                placeholder="{{ 'Search...'| translate}}"
                                style="font-size: 13px; margin-left: 35px; float: left;" class="form-control">
                        </div>
                    </div>
                    <div class="col-sm-1 col-md-1 ml-3 float-right">
                        <div class="form-group">
                            <button type="button" (click)="onFilter2(search.value)" #myButton
                                class="btn btn-primary btn-sm">Search</button>
                        </div>
                    </div>
                </div>
                <div class="row ml-5 mt-3  p-0 " *ngIf="isCompanyFilter || isExpenseFilter">
                    <div class="col-sm-3 col-md-3" *ngIf="isCompanyFilter">
                        <div class="form-group">
                            <ng-select placeholder="Select Company" [items]="CompanyList" bindLabel="name"
                                (open)="getCompanyList()" [multiple]="true" (change)="onCompanyChange($event)"
                                bindValue="id"></ng-select>
                        </div>
                    </div>
                    <div [ngClass]="isCompanyFilter ? 'col-sm-3 col-md-3' : 'col-sm-4 col-md-4' ">
                        <div class="form-group">
                            <ng-select placeholder="Select Financial Year" (open)="getFinacialYearList()"
                                (change)="onYearChange($event?.value)" [items]="yearList2" bindLabel="name"
                                bindValue="value">
                            </ng-select>
                        </div>
                    </div>
                    <div [ngClass]="isCompanyFilter ? 'col-sm-3 col-md-3' : 'col-sm-4 col-md-4' ">
                        <div class="form-group">
                            <ng-select [(ngModel)]="monthModal" placeholder="Select Month" [items]="monthList"
                                bindLabel="name" bindValue="month" (change)="onMonthChang($event)">
                            </ng-select>
                        </div>
                    </div>
                    <div [ngClass]="isCompanyFilter ? 'col-sm-3 col-md-3' : 'col-sm-4 col-md-4' ">
                        <div class="form-group">
                            <i (click)="exportToExcelData()" ngbTooltip="Export to Excel"
                                class='fa fa-file-excel-o mt-1 excelBtn'></i>
                            <i class='fa fa-file-pdf-o ml-4 mt-1 pdfBtn' (click)="exportToPdfData()"
                                ngbTooltip="Export to Pdf"></i>
                        </div>
                    </div>
                    <!-- <div class="col-sm-2 col-md-2" *ngIf="isCompanyFilter ||isExpenseFilter">
                        <div class="form-group">
                            <i class='fa fa-file-pdf-o' (click)="exportToPdfData()" ngbTooltip="Export PDF"
                                role="button" style="color:red;font-size: 30px;"></i>
                        </div>
                    </div> -->
                </div>
                <div class="card-body custom-datatable">
                    <div class="d-none">
                        <div class="form-group">
                            <select class="form-control" name="state" id="maxRows" style="width:70px">
                                <option value="10">10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                                <option value="70">70</option>
                                <option value="100">100</option>
                                <option value="5000">Show ALL Rows</option>
                            </select>
                        </div>

                    </div>



                    <div class="table-responsive" style="overflow-x: auto;">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <ng-container *ngFor="let column of settings?.columns">
                                        <ng-container>
                                            <th width="{{column.width}}" style="font-size:14px;"
                                                (click)="column.isFilterAllow?onSort(column.rowKey):false"
                                                style="cursor:pointer; user-select: none;">
                                                {{column.default | translate}}
                                                <span *ngIf="column.isFilterAllow ===true"><i class="fa fa-fw fa-sort"
                                                        style="font-size:10px"></i>
                                                </span>
                                            </th>
                                        </ng-container>
                                    </ng-container>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngFor="let data of dataSourceList;let i = index">
                                    <tr>
                                        <ng-container>
                                            <td>{{((pagination.page-1) * pagination.pageSize) + (i+1)}} </td>
                                        </ng-container>

                                        <ng-container *ngFor="let col of dataKey">
                                            <ng-container *ngFor="let key of ObjetKyes(data)">
                                                <ng-container *ngIf="key === col.column">
                                                    <ng-container *ngIf="key !=='index'">
                                                        <td>
                                                            <ng-container *ngIf="col.columnValue !==null">
                                                                <ng-container [ngTemplateOutlet]="col.columnValue"
                                                                    [ngTemplateOutletContext]="{data:data,indexOfelement:i}">
                                                                </ng-container>
                                                            </ng-container>
                                                            <ng-container *ngIf="col.columnValue ===null">
                                                                <ng-container *ngIf="data[key]!== null">
                                                                    <span>{{data[key]}}</span>
                                                                </ng-container>
                                                                <ng-container *ngIf="data[key] === null">
                                                                    <span>-</span>
                                                                </ng-container>
                                                            </ng-container>
                                                        </td>
                                                    </ng-container>
                                                </ng-container>
                                            </ng-container>
                                        </ng-container>
                                    </tr>
                                    <ng-container *ngIf="settings.customControl">
                                        <ng-container [ngTemplateOutlet]="settings.customControl"
                                            [ngTemplateOutletContext]="{data:data,indexOfelement:i}">
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                                <tr *ngIf="isInvoicePage">
                                    <td colspan="7" class="text-right font-weight-bold">Total</td>
                                    <td class="font-weight-bold">{{finalTotalAmount | currency:"INR"}}</td>
                                    <td class="font-weight-bold">{{finalPaidAmount | currency:"INR"}}</td>
                                    <td class="font-weight-bold">{{finalRemainingAmount | currency:"INR"}}</td>
                                </tr>
                                <!-- implemnet custome Value -->

                                <!-- End of custome value -->
                            </tbody>
                        </table>

                    </div>
                    <ng-container *ngIf="settings?.pageTotalLength !==0">
                        <div class="row justify-content-md-between align-items-md-center mt-2">
                            <div class="col-7 text-md-left float-md-left">
                                <br>
                                {{ 'Showing'|translate}} {{((pagination.page-1) * pagination.pageSize) + (1)}}
                                {{'to'|translate}}
                                {{((pagination.page-1) * pagination.pageSize) + (1)-1 + (settings.totalElements)}}
                                {{'of'|translate}} {{settings.pageTotalLength}}
                            </div>
                            <div class="col-5">
                                <div class="text-md-right float-md-right mt-2">
                                    <ngb-pagination class="d-flex justify-content-end"
                                        (pageChange)="onPageChange($event)" [rotate]="true" [ellipses]="false"
                                        [boundaryLinks]="true" [maxSize]="2" [collectionSize]="settings.pageTotalLength"
                                        [(page)]="page">
                                    </ngb-pagination>

                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>